<template>
  <div>
    <Header :title="'预约开户'" :back="true"/>
    <div  class="bbox">
    
      <div class="title">即刻预约</div>
      <div class="box-items">
        <div class="form-item">
          <div>姓名</div>
          <el-input
            @focus="tip1=''"
            placeholder="请输入姓名"
            v-model="name">
          </el-input>
        </div>
        <div class="form-item re">
          <div>手机号</div>
          <div class="code theme-color" @click="getCode">{{codeText}}</div>
          <el-input
            @focus="tip1=''"
            placeholder="请输入姓名"
            v-model="tel">
          </el-input>
        </div>
        <div class="form-item">
          <div>验证码</div>
          <el-input
            @focus="tip1=''"
            placeholder="请输入姓名"
            v-model="code">
          </el-input>
        </div>
        <div class="error-tips" v-if="tip1">{{tip1}}</div>
        
      </div>
      <div class="title">您希望办理哪家银行的香港账户？</div>
        <div class="box-items">
          <el-select v-model="bank" style="width:100%;">
            <el-option value="hsb" label="恒生银行"></el-option>
            <el-option value="cmbc" label="民生银行"></el-option>
            <el-option value="icbc" label="工商银行"></el-option>
            <el-option value="ccb" label="建设银行"></el-option>
            <el-option value="cmb" label="招商银行"></el-option>
            <el-option value="boc" label="中国银行"></el-option>
            <el-option value="hsbc" label="汇丰银行"></el-option>
            <el-option value="other" label="其他"></el-option>
          </el-select>
        </div>
    </div>
     <div class="finacing-footer">
      <div class="add-btn theme-bgcolor" @click="open">一键预约</div>
    </div>
  </div>
</template>
<script>
import Header from '@/components/Header'
export default {
  components: {
    Header
  },
  data(){
    return{
      type:this.$route.query.type||'hongkong',
      bank:'hsb',
      name:'',
      tel:'',
      code:'',
      codeText:"获取验证码",
      disabledshow:false,
      tip1:'',
      timer:'',
      date:60
     
    }
  },
  created(){
    let info =  JSON.parse(localStorage.getItem('info'));
    this.name = info.name 
    this.tel = info.tel?info.tel:''
  },
  methods:{
    // 获取验证码
    getCode() {
      if(this.disabledshow){
        return false
      }
      if (this.tel == '') {
        this.tip1 = "请输入手机号"
      } else {
        this.timer = null
        let params = {
          account:this.tel,
          type: 1
        }
        this.$store
          .dispatch('auth/getcodes',params)
          .then(res => {
            if (res.code === 0) {
              this.disabledshow = true
              if (this.date === 60) {
                this.timer = setInterval(() => {
                  this.date--
                  this.codeText = this.date + 's'
                  if (this.date === 0) {
                    this.date = 60
                    this.disabledshow = false
                    this.codeText = '获取验证码'
                    clearInterval(this.timer)
                  }
                }, 1000)
              }
            } else {
              this.disabledshow = false
              this.date = 60
              this.codeText = '获取验证码'
              clearInterval(this.timer)
            }
          })
          .catch(err => {
            this.text = err.body.msg
          })
      }
    },
    open() {
      if(!this.name){
        this.tip1="请输入姓名"
        return false
      }else if(!this.tel){
        this.tip1="请输入手机号"
        return false
      }else if(!this.code){
        this.tip2="请输入验证码"
        return false
      }
      let params = {
        name:this.name,
        tel:this.tel,
        verify:this.code,
        bank:this.bank,
        from:'account',
        refer:'app'
      }
      this.$store.dispatch('transation/reaccount',params).then(res => {
        if(res){
          this.$confirm('请保持电话畅通，24小时内将有专人为您服务！有任何疑问也可联系在线客服获得帮助', '提交成功', {
            confirmButtonText: '联系客服',
            showCancelButton:false,
            type: 'success',
            center: true
          }).then(() => {
            location.href="tel:4000362988"
          })
        }
      })
    }
  }
  
}
</script>
<style lang="scss" scoped>
.form-item{
  margin: 1rem 0rem;
  >:first-child{
    margin-bottom:5px
  }
}
.re{
  position: relative;
  .code{
    position: absolute;
    top:35px;
    right: 8px;
    z-index: 2;
  }
}
.finacing-footer{
  // background: #fff;
  position: fixed;
  bottom: 0px;
  width: 95%;
  padding: 2.5%;
  .add-btn{
    border-radius:8px;
    width: 100%;
    text-align: center;
    line-height: 2.5;
    color: #fff;
  }
}
.error-tips{
  font-size: .9rem;
  color: #f56c6c;
}

</style>