var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Header", { attrs: { title: "预约开户", back: true } }),
      _c("div", { staticClass: "bbox" }, [
        _c("div", { staticClass: "title" }, [_vm._v("即刻预约")]),
        _c("div", { staticClass: "box-items" }, [
          _c(
            "div",
            { staticClass: "form-item" },
            [
              _c("div", [_vm._v("姓名")]),
              _c("el-input", {
                attrs: { placeholder: "请输入姓名" },
                on: {
                  focus: function ($event) {
                    _vm.tip1 = ""
                  },
                },
                model: {
                  value: _vm.name,
                  callback: function ($$v) {
                    _vm.name = $$v
                  },
                  expression: "name",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-item re" },
            [
              _c("div", [_vm._v("手机号")]),
              _c(
                "div",
                { staticClass: "code theme-color", on: { click: _vm.getCode } },
                [_vm._v(_vm._s(_vm.codeText))]
              ),
              _c("el-input", {
                attrs: { placeholder: "请输入姓名" },
                on: {
                  focus: function ($event) {
                    _vm.tip1 = ""
                  },
                },
                model: {
                  value: _vm.tel,
                  callback: function ($$v) {
                    _vm.tel = $$v
                  },
                  expression: "tel",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-item" },
            [
              _c("div", [_vm._v("验证码")]),
              _c("el-input", {
                attrs: { placeholder: "请输入姓名" },
                on: {
                  focus: function ($event) {
                    _vm.tip1 = ""
                  },
                },
                model: {
                  value: _vm.code,
                  callback: function ($$v) {
                    _vm.code = $$v
                  },
                  expression: "code",
                },
              }),
            ],
            1
          ),
          _vm.tip1
            ? _c("div", { staticClass: "error-tips" }, [
                _vm._v(_vm._s(_vm.tip1)),
              ])
            : _vm._e(),
        ]),
        _c("div", { staticClass: "title" }, [
          _vm._v("您希望办理哪家银行的香港账户？"),
        ]),
        _c(
          "div",
          { staticClass: "box-items" },
          [
            _c(
              "el-select",
              {
                staticStyle: { width: "100%" },
                model: {
                  value: _vm.bank,
                  callback: function ($$v) {
                    _vm.bank = $$v
                  },
                  expression: "bank",
                },
              },
              [
                _c("el-option", { attrs: { value: "hsb", label: "恒生银行" } }),
                _c("el-option", {
                  attrs: { value: "cmbc", label: "民生银行" },
                }),
                _c("el-option", {
                  attrs: { value: "icbc", label: "工商银行" },
                }),
                _c("el-option", { attrs: { value: "ccb", label: "建设银行" } }),
                _c("el-option", { attrs: { value: "cmb", label: "招商银行" } }),
                _c("el-option", { attrs: { value: "boc", label: "中国银行" } }),
                _c("el-option", {
                  attrs: { value: "hsbc", label: "汇丰银行" },
                }),
                _c("el-option", { attrs: { value: "other", label: "其他" } }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "finacing-footer" }, [
        _c(
          "div",
          { staticClass: "add-btn theme-bgcolor", on: { click: _vm.open } },
          [_vm._v("一键预约")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }